<template>
  <div>
    <b-row>
      <b-col cols="9">
        <info />
      </b-col>
      <b-col cols="3" />
    </b-row>
  </div>
</template>

<script>

import {BRow, BCol} from 'bootstrap-vue'

import Info from './include/Info.vue'

export default {
  components: {
    BRow,
    BCol,
    Info
  },
}
</script>
